var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"9"}},[_c('Fieldset',{attrs:{"id":"companyinfo","title":_vm.$t('Company Info')}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Name') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Name')},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Country') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Country')},model:{value:(_vm.model.country),callback:function ($$v) {_vm.$set(_vm.model, "country", $$v)},expression:"model.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Address') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Address')},model:{value:(_vm.model.address),callback:function ($$v) {_vm.$set(_vm.model, "address", $$v)},expression:"model.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Tax code') + ":","label-cols-md":"12"}},[_c('b-form-input',{attrs:{"placeholder":"Tax code"},model:{value:(_vm.model.tin),callback:function ($$v) {_vm.$set(_vm.model, "tin", $$v)},expression:"model.tin"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Company website') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"NaCompany websiteme"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Name')},model:{value:(_vm.model.web_company),callback:function ($$v) {_vm.$set(_vm.model, "web_company", $$v)},expression:"model.web_company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Zip code:","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Zip code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Zip code"},model:{value:(_vm.model.zip_code),callback:function ($$v) {_vm.$set(_vm.model, "zip_code", $$v)},expression:"model.zip_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Certificate of Business registration code') + ":","label-cols-md":"12"}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('Certificate of Business registration code')},model:{value:(_vm.model.business_license),callback:function ($$v) {_vm.$set(_vm.model, "business_license", $$v)},expression:"model.business_license"}})],1)],1)],1)],1),_c('Fieldset',{attrs:{"id":"contactinfo","title":_vm.$t('Contact Info')}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Contact name') +":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Contact name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Contact name')},model:{value:(_vm.model.contact_name),callback:function ($$v) {_vm.$set(_vm.model, "contact_name", $$v)},expression:"model.contact_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"*Email:","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Email"},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Phone') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Phone')},model:{value:(_vm.model.phone),callback:function ($$v) {_vm.$set(_vm.model, "phone", $$v)},expression:"model.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',{attrs:{"label":_vm.$t('Short content') + ":","label-cols-md":"12"}},[_c('b-form-textarea',{attrs:{"placeholder":_vm.$t('Short content')},model:{value:(_vm.model.short_content),callback:function ($$v) {_vm.$set(_vm.model, "short_content", $$v)},expression:"model.short_content"}})],1)],1),_c('Fieldset',{attrs:{"id":"paymentinfo","title":_vm.$t('Payment Info')}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Bank name') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Bank name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Bank name')},model:{value:(_vm.model.name_bank),callback:function ($$v) {_vm.$set(_vm.model, "name_bank", $$v)},expression:"model.name_bank"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"*Swift code:","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Swift code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Swift code"},model:{value:(_vm.model.swift_code),callback:function ($$v) {_vm.$set(_vm.model, "swift_code", $$v)},expression:"model.swift_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Account name') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Account name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Account name')},model:{value:(_vm.model.account_holder_bank),callback:function ($$v) {_vm.$set(_vm.model, "account_holder_bank", $$v)},expression:"model.account_holder_bank"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Account number') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Account Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number","placeholder":_vm.$t('Account number')},model:{value:(_vm.model.number_bank),callback:function ($$v) {_vm.$set(_vm.model, "number_bank", $$v)},expression:"model.number_bank"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('div',{staticClass:"position-sticky top"},[_c('Fieldset',{attrs:{"id":"avatar","title":_vm.$t('Avatar')}},[_c('FeatureImage',{attrs:{"avatar":_vm.avatar},on:{"previewFiles":_vm.previewFiles}})],1),_c('Fieldset',{attrs:{"id":"status","title":_vm.$t('Status')}},[_c('b-form-group',{attrs:{"label":_vm.$t('Position')+":","label-cols-md":"6"}},[_c('b-form-input',{attrs:{"type":"number","placeholder":_vm.$t('Position')},model:{value:(_vm.model.position),callback:function ($$v) {_vm.$set(_vm.model, "position", $$v)},expression:"model.position"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Display on/off')+":","label-cols-md":"6"}},[_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"checked":"true","name":"check-button","switch":""},model:{value:(_vm.model.is_active),callback:function ($$v) {_vm.$set(_vm.model, "is_active", $$v)},expression:"model.is_active"}})],1),_c('hr'),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"text-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" "+_vm._s(_vm.$t('Update'))+" ")])],1)],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }